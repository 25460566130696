const ErrorPage = (props) => {
  const { error, message } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <h1>Error: {error}</h1>
      <h5>{message}</h5>
    </div>
  );
};

export default ErrorPage;
