import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import CustomTitle from './CustomTitle.js';
import { BsTrashFill } from 'react-icons/bs';

const MeetingTitle = (props) => {
  const {
    meetingTitle,
    setMeetingTitle,
    showCustomTitle,
    setShowCustomTitle,
    presets,
  } = props;
  const handleShowInput = () => {
    setShowCustomTitle(true);
  };

  return (
    <Row>
      <h3>Meeting Title:</h3>
      {meetingTitle.length ? (
        <Row>
          <Col>
            <h1>{meetingTitle}</h1>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="danger"
              style={{ width: '5rem' }}
              onClick={() => setMeetingTitle('')}
            >
              <BsTrashFill />
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <ButtonGroup>
            {presets.map((preset, i) => (
              <Button
                key={i}
                onClick={() => {
                  setMeetingTitle(preset);
                }}
              >
                {preset}
              </Button>
            ))}
            <Button onClick={handleShowInput}>Custom Title</Button>
          </ButtonGroup>
          <CustomTitle
            showCustomTitle={showCustomTitle}
            setShowCustomTitle={setShowCustomTitle}
            setInput={setMeetingTitle}
          />
        </>
      )}
    </Row>
  );
};

export default MeetingTitle;
