import {
  PeopleCard,
  MeetingTitle,
  Scanner,
  ManuallyAdd,
  ManuallyAddSearch,
} from './';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ListGroup,
} from 'react-bootstrap';
import { BsSearch, BsPersonPlusFill } from 'react-icons/bs';

const Home = (props) => {
  const {
    meetingTitle,
    setMeetingTitle,
    people,
    attendees,
    setAttendees,
    showCustomTitle,
    setShowCustomTitle,
    showManuallyAdd,
    setShowManuallyAdd,
    showManuallyAddSearch,
    setShowManuallyAddSearch,
    presets,
  } = props;
  return (
    <>
      <Container>
        <MeetingTitle
          meetingTitle={meetingTitle}
          setMeetingTitle={setMeetingTitle}
          showCustomTitle={showCustomTitle}
          setShowCustomTitle={setShowCustomTitle}
          presets={presets}
        />
        <Row>
          <Container>
            <h3>Scanner:</h3>
            <Scanner
              meetingTitle={meetingTitle}
              showCustomTitle={showCustomTitle}
              showManuallyAdd={showManuallyAdd}
              showManuallyAddSearch={showManuallyAddSearch}
              people={people}
              attendees={attendees}
              setAttendees={setAttendees}
            />
          </Container>
        </Row>
        <Row>
          <Container>
            <h3>Manually Add:</h3>
            <ButtonGroup>
              <Button onClick={() => setShowManuallyAddSearch(true)}>
                <BsSearch /> Open Search
              </Button>
              <Button onClick={() => setShowManuallyAdd(true)}>
                <BsPersonPlusFill /> Open Manual Add
              </Button>
            </ButtonGroup>
          </Container>
        </Row>
        <Row>
          <Col>
            <h3>Attendees:</h3>
            <ListGroup>
              {attendees.length ? (
                attendees.map((person, i) => (
                  <PeopleCard
                    key={i}
                    person={person}
                    attendees={attendees}
                    setAttendees={setAttendees}
                    style={{ minWidth: '480px' }}
                  />
                ))
              ) : (
                <h5 style={{ textAlign: 'center' }}>Nobody yet</h5>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <ManuallyAdd
        showManuallyAdd={showManuallyAdd}
        setShowManuallyAdd={setShowManuallyAdd}
        attendees={attendees}
        setAttendees={setAttendees}
      />
      <ManuallyAddSearch
        showManuallyAddSearch={showManuallyAddSearch}
        setShowManuallyAddSearch={setShowManuallyAddSearch}
        people={people}
        attendees={attendees}
        setAttendees={setAttendees}
      />
    </>
  );
};

export default Home;
