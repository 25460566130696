import { Modal, Form, Button, ListGroup, Container } from 'react-bootstrap';
import moment from 'moment';
import { useState, useEffect } from 'react';
import {
  BsPersonDashFill,
  BsPersonPlusFill,
  BsFillTrashFill,
} from 'react-icons/bs';
import { ManuallyAddSearch, DeleteMeeting } from './';
import { editMeeting } from '../api/attendanceApi';

const EditMeeting = (props) => {
  const {
    showEditMeeting,
    setShowEditMeeting,
    selectedMeeting,
    setSelectedMeeting,
    people,
    meetings,
    setMeetings,
  } = props;
  const [editedMeeting, setEditedMeeting] = useState({});
  const [changedFields, setChangedFields] = useState({});
  const [showManuallyAddSearch, setShowManuallyAddSearch] = useState(false);
  const [showDeleteMeeting, setShowDeleteMeeting] = useState(false);

  useEffect(() => {
    setEditedMeeting({
      ...selectedMeeting,
      attendees: JSON.parse(selectedMeeting.attendees || '[]'),
    });
    setChangedFields({ id: selectedMeeting.id });
  }, [selectedMeeting]);

  const handleClose = () => {
    setSelectedMeeting({});
    setShowEditMeeting(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (changedFields.hasOwnProperty('attendees')) {
      changedFields.attendees = JSON.stringify(changedFields.attendees);
    }
    if (changedFields.hasOwnProperty('date')) {
      changedFields.date = moment(changedFields.date).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }
    const edited = await editMeeting(changedFields);
    const newMeetings = [...meetings];
    newMeetings[newMeetings.findIndex((e) => e.id === edited.id)] = edited;
    setMeetings(newMeetings);
    handleClose();
  };

  const changeHandler = (key, value) => {
    const clonedEdit = { ...editedMeeting };
    clonedEdit[key] = value;
    setEditedMeeting(clonedEdit);

    const clonedFields = { ...changedFields };
    selectedMeeting[key] === value
      ? delete clonedFields[key]
      : (clonedFields[key] = value);
    setChangedFields(clonedFields);
  };

  return (
    <>
      <Modal show={showEditMeeting} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Editing: {selectedMeeting.meetingTitle} on{' '}
            {moment(selectedMeeting.date).format('MM/DD/YYYY')} @{' '}
            {moment(selectedMeeting.date).format('h:mm A')}{' '}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Meeting Title</Form.Label>
              <Form.Control
                value={editedMeeting.meetingTitle || ''}
                onChange={(event) => {
                  changeHandler('meetingTitle', event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <Form.Control
                value={editedMeeting.date || ''}
                type="datetime-local"
                onChange={(event) => {
                  changeHandler('date', event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Attendees</Form.Label>
              <Container style={{ paddingLeft: '0' }}>
                <Button
                  onClick={() => setShowManuallyAddSearch(true)}
                  style={{ marginBottom: '1rem' }}
                >
                  <BsPersonPlusFill /> Add Attendee
                </Button>
              </Container>
              <ListGroup>
                {editedMeeting.attendees?.map((value, i) => (
                  <ListGroup.Item
                    key={i}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span>{value['Who']}</span>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => {
                        changeHandler(
                          'attendees',
                          editedMeeting.attendees.filter(
                            (e) => e['Who'] !== value['Who']
                          )
                        );
                      }}
                    >
                      <BsPersonDashFill />
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
            <Form.Group
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <Button
                variant="danger"
                onClick={() => setShowDeleteMeeting(true)}
              >
                <BsFillTrashFill /> Delete Meeting
              </Button>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="success"
              disabled={Object.keys(changedFields).length <= 1}
            >
              Edit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ManuallyAddSearch
        showManuallyAddSearch={showManuallyAddSearch}
        setShowManuallyAddSearch={setShowManuallyAddSearch}
        attendees={editedMeeting.attendees}
        setAttendees={(a) => {
          setEditedMeeting({ ...editedMeeting, attendees: a });
          const clonedFields = { ...changedFields };
          JSON.stringify(selectedMeeting.attendees) === JSON.stringify(a)
            ? delete clonedFields.attendees
            : (clonedFields.attendees = a);
          setChangedFields(clonedFields);
        }}
        people={people}
      />
      <DeleteMeeting
        showDeleteMeeting={showDeleteMeeting}
        setShowDeleteMeeting={setShowDeleteMeeting}
        selectedMeeting={selectedMeeting}
        handleCloseParent={handleClose}
        controlParentVisibility={(bool) => setShowEditMeeting(bool)}
        meetings={meetings}
        setMeetings={setMeetings}
      />
    </>
  );
};

export default EditMeeting;
