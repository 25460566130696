import { getMeetingsInRange, getMeetingTitles, getReport } from '../api/attendanceApi';
import { useState, useEffect, useCallback } from 'react';
import { BsFillPencilFill } from 'react-icons/bs';
import moment from 'moment';
import {
  Container,
  Card,
  ListGroup,
  Form,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import { EditMeeting } from './';

const Reports = (props) => {
  const { people } = props;
  const [meetings, setMeetings] = useState([]);
  const [meetingTitles, setMeetingTitles] = useState([]);
  const [checkedMeetings, setCheckedMeetings] = useState([]);
  const [fromDate, setFromDate] = useState(
    moment().add(-3, 'months').format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [showEditMeeting, setShowEditMeeting] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState({});

  const setFromRange = useCallback(() => {
    if (!checkedMeetings.length) {
      setMeetings([]);
      return;
    }
    getMeetingsInRange({
      fromDate,
      toDate,
      meetingTitles: checkedMeetings,
    }).then((meetings) => setMeetings(meetings));
  }, [fromDate, toDate, checkedMeetings]);

  const initialSetup = useCallback(() => {
    getMeetingTitles()
      .then((titles) => titles?.map((title) => title.meetingTitle))
      .then((uniqueTitles) => {
        setMeetingTitles(uniqueTitles);
        setCheckedMeetings(uniqueTitles);
      })
      .then(() => setFromRange());
  }, [setFromRange]);

  useEffect(() => {
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFromRange();
  }, [setFromRange]);

  const resetEverything = () => {
    setFromDate(moment().add(-3, 'months').format('YYYY-MM-DD'));
    setToDate(moment().format('YYYY-MM-DD'));
    initialSetup();
  };

  const rowStyle = { marginTop: '.5rem', marginBottom: '1rem' };

  return (
    <>
      <Container>
        <Row style={rowStyle}>
          <h3>Meeting History</h3>
          <Col>
            <Button
              disabled={!checkedMeetings.length}
              onClick={() =>
                getReport({
                  fromDate,
                  toDate,
                  meetingTitles: checkedMeetings,
                })
              }
            >
              Get Report In Excel
            </Button>
          </Col>
          <Col style={{ float: 'right' }}>
            <Button
              onClick={resetEverything}
              variant="danger"
              style={{ float: 'right' }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <h5>Meeting Type</h5>
          <Form>
            {meetingTitles?.map((meetingTitle, i) => (
              <Form.Check
                key={i}
                label={meetingTitle}
                checked={
                  checkedMeetings.filter((e) => e === meetingTitle).length
                }
                inline
                onChange={(e) => {
                  e.target.checked
                    ? setCheckedMeetings([...checkedMeetings, meetingTitle])
                    : setCheckedMeetings(
                        checkedMeetings.filter((m) => m !== meetingTitle)
                      );
                }}
              />
            ))}
          </Form>
        </Row>
        <Row style={rowStyle}>
          <h5>Meeting Range</h5>
          <Col>
            <Form.Label>From</Form.Label>
            <Form.Control
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label>To</Form.Label>
            <Form.Control
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={rowStyle}>
          {meetings?.map((meeting, idx) => {
            return (
              <Card key={idx} style={{ marginBottom: '1rem' }}>
                <Card.Body>
                  <Card.Title
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{meeting.meetingTitle}</span>
                    <span>
                      {moment(meeting.date).format('dddd MM/DD/YYYY')} @{' '}
                      {moment(meeting.date).format('h:mm A')}
                    </span>
                  </Card.Title>
                  <Container
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      marginBottom: '0.5rem',
                      padding: '0',
                    }}
                  >
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        setSelectedMeeting(meeting);
                        setShowEditMeeting(true);
                      }}
                    >
                      <BsFillPencilFill /> Edit
                    </Button>
                  </Container>
                  <ListGroup>
                    {JSON.parse(meeting.attendees).map((attendee, idx) => (
                      <ListGroup.Item key={idx}>{attendee.Who}</ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            );
          })}
        </Row>
      </Container>
      <EditMeeting
        showEditMeeting={showEditMeeting}
        setShowEditMeeting={setShowEditMeeting}
        selectedMeeting={selectedMeeting}
        setSelectedMeeting={setSelectedMeeting}
        people={people}
        meetings={meetings}
        setMeetings={setMeetings}
      />
    </>
  );
};

export default Reports;
