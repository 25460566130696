const Header = (props) => {
  const { logo, product } = props;

  const calcFontSize = (text, max, def) =>
    Math.min((max * def) / text.length, 2.5);

  return (
    <header
      className="grid"
      style={{
        display: 'grid',
        height: '19vh',
        gridTemplateColumns: 'repeat(10, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(5, minmax(0, 1fr))',
      }}
    >
      <img
        src={logo.picture}
        style={{
          gridColumn: '1/3',
          gridRow: '1/6',
          height: '100%',
          width: '100%',
        }}
        alt={logo.alt}
      />
      <div
        style={{
          gridColumn: '4/10',
          gridRow: '2/5',
          textAlign: 'center',
          height: '100%',
        }}
      >
        <h1
          style={{
            color: '#000099',
            fontFamily: "Georgia, 'Times New Roman', Times, serif",
            fontSize:
              calcFontSize(
                product['Description'] + product['Pack Size'],
                50,
                3.2
              ) + 'rem',
          }}
        >
          {product['Description'] ?? ''}
        </h1>
        <h1
          style={{
            color: '#000099',
            fontFamily: "Georgia, 'Times New Roman', Times, serif",
            fontSize:
              calcFontSize(
                product['Description'] + product['Pack Size'],
                50,
                3.2
              ) + 'rem',
          }}
        >
          {product['Pack Size'] ?? ''}
        </h1>
      </div>
    </header>
  );
};

export default Header;
