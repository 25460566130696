import { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { addSDS } from '../api/sdsListApi';

const AddSDS = (props) => {
  const {
    showAddSDS,
    setShowAddSDS,
    SDSList,
    maintenanceSDSList,
    setMaintenanceSDSList,
    sanitationSDSList,
    setSanitationSDSList,
    pestControlSDSList,
    setPestControlSDSList,
  } = props;
  const [newSDS, setNewSDS] = useState({});
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [validEnglishFile, setValidEnglishFile] = useState(true);
  const [validSpanishFile, setValidSpanishFile] = useState(true);

  useEffect(() => {
    if (SDSList.length) {
      const obj = Object.fromEntries(
        Object.keys(SDSList[0]).map((key) => [key, ''])
      );
      delete obj.id;
      obj['Department'] = 'Maintenance';
      obj['Active'] = 1;
      setNewSDS(obj);
    }
  }, [SDSList]);

  const handleClose = () => {
    setShowAddSDS(false);
  };

  const updateList = (list, updateFunc, returned) => {
    updateFunc([...list, returned]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const returned = await addSDS(newSDS);
    switch (returned['Department']) {
      case 'Maintenance':
        updateList(maintenanceSDSList, setMaintenanceSDSList, returned);
        break;
      case 'Sanitation':
        updateList(sanitationSDSList, setSanitationSDSList, returned);
        break;
      case 'Pest Control':
        updateList(pestControlSDSList, setPestControlSDSList, returned);
        break;
      default:
        console.error('Updated result returned an unknown department');
    }
    handleClose();
  };

  const changeHandler = (key, value) => {
    const clonedSDS = { ...newSDS };
    clonedSDS[key] = value;
    setNewSDS(clonedSDS);
  };

  const regexHandler = (regex, updateFunc, value) => {
    const re = new RegExp(regex);

    value.length ? updateFunc(re.test(value)) : updateFunc(true);
  };

  const getFormElement = (key, value, i) => {
    switch (key) {
      case 'Emergency Phone #':
        return (
          <Form.Control
            key={i}
            value={value ?? ''}
            placeholder={key}
            style={{ marginBottom: '.5rem' }}
            onChange={(event) => {
              changeHandler(key, event.target.value);
              regexHandler(
                // eslint-disable-next-line no-useless-escape
                /^(?<country>\+?[0-9]{1,2}[ .-]?)?\(?(?<area>[0-9]{3})\)?(?<delimeter>[ .-]?)(?<three>[0-9]{3})\k<delimeter>(?<four>[0-9]{4})$/,
                setValidPhoneNumber,
                event.target.value
              );
            }}
            type="tel"
            isInvalid={newSDS[key].length && !validPhoneNumber}
            required
          />
        );
      case 'Department':
        return (
          <Form.Select
            defaultValue={value}
            onChange={(event) => changeHandler(key, event.target.value)}
          >
            <option value="Maintenance">Maintenance</option>
            <option value="Sanitation">Sanitation</option>
            <option value="Pest Control">Pest Control</option>
          </Form.Select>
        );
      case 'Active':
        return (
          <Form.Select
            defaultValue={value}
            onChange={(event) => changeHandler(key, event.target.value)}
          >
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </Form.Select>
        );
      case 'English File':
        return (
          <Form.Control
            key={i}
            value={value ?? ''}
            placeholder={key}
            style={{ marginBottom: '.5rem' }}
            onChange={(event) => {
              changeHandler(key, event.target.value);
              regexHandler(
                // eslint-disable-next-line no-useless-escape
                /^[A-Z]\:(\\[\w\d\ \^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\%\.\+\~\_]+)*$/,
                setValidEnglishFile,
                event.target.value
              );
            }}
            isInvalid={newSDS[key].length && !validEnglishFile}
            required
          />
        );
      case 'Spanish File':
        return (
          <Form.Control
            key={i}
            value={value ?? ''}
            placeholder={key}
            style={{ marginBottom: '.5rem' }}
            onChange={(event) => {
              changeHandler(key, event.target.value);
              regexHandler(
                // eslint-disable-next-line no-useless-escape
                /^[A-Z]\:(\\[\w\d\ \^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\%\.\+\~\_]+)*$/,
                setValidSpanishFile,
                event.target.value
              );
            }}
            isInvalid={newSDS[key].length && !validSpanishFile}
          />
        );
      default:
        return (
          <Form.Control
            key={i}
            value={value ?? ''}
            placeholder={key}
            style={{ marginBottom: '.5rem' }}
            onChange={(event) => changeHandler(key, event.target.value)}
            required
          />
        );
    }
  };

  return (
    <Modal show={showAddSDS} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add SDS</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {Object.entries(newSDS).map(([key, value], i) => {
            return (
              <Form.Group key={i}>
                <Form.Label>{key}</Form.Label>
                {getFormElement(key, value)}
              </Form.Group>
            );
          })}
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'right' }}>
          <Button onClick={handleClose} variant="danger">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '1rem' }}
            type="submit"
            variant="success"
            disabled={
              !(validEnglishFile && validSpanishFile && validPhoneNumber)
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddSDS;
