import { Navbar, Container, Form, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NavHeader = (props) => {
  const { searchValue, setSearchValue } = props;
  const navigate = useNavigate();

  return (
    <Navbar bg="dark" variant="dark" sticky="top">
      <Navbar.Brand
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigate('/sds');
        }}
      >
        🦺 FVF SDS Finder
      </Navbar.Brand>
      <Container style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Nav style={{ width: '100%' }}>
          <Nav.Item style={{ width: '100%' }}>
            <Form.Control
              value={searchValue}
              placeholder="Search"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavHeader;
