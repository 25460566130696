import { Modal, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { deleteMeeting } from '../api/attendanceApi';

const DeleteMeeting = (props) => {
  const {
    showDeleteMeeting,
    setShowDeleteMeeting,
    selectedMeeting,
    handleCloseParent,
    controlParentVisibility,
    meetings,
    setMeetings,
  } = props;

  const [yesIAmSure, setYesIAmSure] = useState(false);

  useEffect(() => {
    showDeleteMeeting && controlParentVisibility(!showDeleteMeeting);
  }, [showDeleteMeeting, controlParentVisibility]);

  const handleClose = (parentVis = true) => {
    setShowDeleteMeeting(false);
    setYesIAmSure(false);
    controlParentVisibility(parentVis);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const deleted = await deleteMeeting(selectedMeeting.id);
    setMeetings(meetings.filter((e) => e.id !== deleted.id));
    handleClose(false);
    handleCloseParent();
  };

  const title = (
    <span>
      {selectedMeeting.meetingTitle} on{' '}
      {moment(selectedMeeting.date).format('MM/DD/YYYY')} @{' '}
      {moment(selectedMeeting.date).format('h:mm A')}
    </span>
  );

  return (
    <Modal show={showDeleteMeeting} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {title} ?</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <p>Are you sure you want delete "{title}" ?</p>
          <p>This action cannot be undone.</p>
          <Form.Check
            type="checkbox"
            label="I accept the responsibilities for my actions"
            checked={yesIAmSure}
            onChange={(e) => setYesIAmSure(e.target.checked)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button variant="danger" type="submit" disabled={!yesIAmSure}>
            Delete
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DeleteMeeting;
