import GC_logo from '../data/GC_logo.svg';
import './BlackbookPDF.css';
import {
  BlackbookPDFHeader,
  BlackbookPDFTable,
  BlackbookPDFBuilder,
  Loading,
} from './';
import { Container } from 'react-bootstrap';
import { getBlackbookItemBySKU } from '../api/blackbookPDFApi';
import { useEffect, useState } from 'react';

const BlackbookPDFPrintPage = (props) => {
  const {
    showPageBuilder,
    setShowPageBuilder,
    blackbookPicturesURL,
    selectedPictureIndex,
    setSelectedPictureIndex,
  } = props;
  const [product, setProduct] = useState({});
  const [showLoading, setShowLoading] = useState(false);

  const handleClose = () => {
    setShowPageBuilder(false);
  };

  const getProduct = async () => {
    const SKU = String(window.location)
      .split('?')
      .filter((e) => e.includes('SKU'))[0]
      .split('=')[1];

    const res = await getBlackbookItemBySKU(SKU);

    return res;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(props.product).length) {
        setProduct(props.product);
        document.title = `GoldCoast - ${product['SKU']}`;
        return;
      }

      setShowLoading(true);
      try {
        const res = await getProduct();

        const possibleProductPic =
          (res['pictures']?.length &&
            (res['pictures']
              .split(';')
              .filter((e) => e.toLowerCase().includes('product'))[0] ||
              res['pictures'].split(';')[0])) ||
          '';

        setSelectedPictureIndex(
          res['pictures']?.split(';').findIndex((e) => e === possibleProductPic)
        );

        setProduct({
          ...res,
          Title: res['Description'],
          'Product Picture': possibleProductPic,
        });
        setShowPageBuilder(true);
        document.title = `GoldCoast - ${res['SKU']}`;
      } catch (error) {
        console.error(error);
      } finally {
        setShowLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'block',
          boxSizing: 'border-box',
          position: 'absolute',
          backgroundColor: '#000099',
          width: '100%',
          height: '100%',
          zIndex: '-2',
        }}
      />
      <div
        style={{
          display: 'block',
          boxSizing: 'border-box',
          position: 'absolute',
          backgroundColor: 'white',
          width: '100%',
          height: '11in',
          clipPath: 'ellipse(96% 95% at 100% 65%)',
          zIndex: '-1',
        }}
      />
      <BlackbookPDFHeader
        product={product}
        logo={{ picture: GC_logo, alt: 'Gold Coast Logo' }}
      />
      <div
        className="grid"
        style={{
          height: '80vh',
          display: 'grid',
          gridTemplateColumns: 'repeat(10, minmax(0, 1fr))',
          gridTemplateRows: 'repeat(10, minmax(0, 1fr))',
        }}
      >
        <div
          style={{
            gridRow: '1/4',
            gridColumn: '4/8',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
          }}
        >
          <img
            src={`https://www.test.freshventurefoods.com/dod/php/blackbookPictures/${product['Product Picture']}`}
            style={{
              height: '100%',
            }}
            alt="Product"
          />
        </div>
        <Container
          style={{
            gridRow: '4/10',
            gridColumn: '2/10',
            overflow: 'clip',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BlackbookPDFTable product={product} />
        </Container>
        <div
          style={{ gridRow: '11/11', gridColumn: '3/9', textAlign: 'center' }}
        >
          <p
            style={{
              fontFamily: "Georgia, 'Times New Roman', Times, serif",
              fontSize: '.85rem',
            }}
          >
            <strong>Gold Coast Packing Inc.</strong>
            <br />
            <i>
              1205 W. Craig Drive • Santa Maria, CA 93458 • (805) 928-2593
              <br />
              Sales@GoldCoastPack.com
            </i>
          </p>
        </div>
      </div>
      <BlackbookPDFBuilder
        showPageBuilder={showPageBuilder}
        handleClose={handleClose}
        selectedProduct={product}
        setSelectedProduct={setProduct}
        selectedPictureIndex={selectedPictureIndex}
        setSelectedPictureIndex={setSelectedPictureIndex}
        blackbookPicturesURL={blackbookPicturesURL}
      />
      <Loading showLoading={showLoading} />
    </div>
  );
};

export default BlackbookPDFPrintPage;
