import { Navbar, Container, Button, Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ConfirmSubmit } from './';
import logo512 from '../data/logo512.png';

const NavigationBar = (props) => {
  const {
    meetingTitle,
    setMeetingTitle,
    attendees,
    setAttendees,
    setShowCustomTitle,
    setShowManuallyAdd,
    setShowManuallyAddSearch,
    showConfirmSubmit,
    setShowConfirmSubmit,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = () => {
    setShowConfirmSubmit(true);
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" sticky="top">
        <Container>
          <Navbar.Brand
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              navigate('/attendance');
            }}
          >
            {/* TODO: Get a better logo for navbar, for now just use emoji */}
            {false ? (
              <img
                src={logo512}
                alt="Fresh Venture Logo"
                style={{
                  height: '3rem',
                  background: 'white',
                  borderRadius: '5%',
                }}
              />
            ) : (
              '🥦 FVF'
            )}{' '}
            Attendance Tracker
          </Navbar.Brand>
        </Container>
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'right',
          }}
        >
          <Nav.Item>
            {location.pathname === '/attendance' ? (
              <Button
                onClick={handleSubmit}
                variant="success"
                style={{ marginLeft: '1rem', width: '10rem' }}
                disabled={!(meetingTitle.length && attendees.length)}
              >
                Submit Meeting
              </Button>
            ) : (
              <Button
                onClick={() => {
                  navigate('/attendance');
                }}
                variant="primary"
                style={{ marginLeft: '1rem', width: '10rem' }}
              >
                Create Meeting
              </Button>
            )}
          </Nav.Item>
          <Nav.Item>
            <Button
              onClick={() => {
                navigate('/attendance/meetings');
              }}
              variant="secondary"
            >
              View History
            </Button>
          </Nav.Item>
        </Container>
      </Navbar>
      <ConfirmSubmit
        showConfirmSubmit={showConfirmSubmit}
        setShowConfirmSubmit={setShowConfirmSubmit}
        meetingTitle={meetingTitle}
        setMeetingTitle={setMeetingTitle}
        attendees={attendees}
        setAttendees={setAttendees}
        setShowCustomTitle={setShowCustomTitle}
        setShowManuallyAdd={setShowManuallyAdd}
        setShowManuallyAddSearch={setShowManuallyAddSearch}
      />
    </>
  );
};

export default NavigationBar;
