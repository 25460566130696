import { useRef, useEffect } from 'react';
import { Modal, ModalBody, Button, Form } from 'react-bootstrap';

const CustomTitle = (props) => {
  const { showCustomTitle, setShowCustomTitle, setInput } = props;
  const handleClose = () => setShowCustomTitle(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showCustomTitle && inputRef.current) inputRef.current.focus();
  }, [showCustomTitle]);

  return (
    <Modal show={showCustomTitle} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Custom Title</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            if (event.target[0].value.length) setInput(event.target[0].value);
          }}
        >
          <Form.Group>
            <Form.Control ref={inputRef} maxLength="49" />
          </Form.Group>
          <Form.Group
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginTop: '1rem',
            }}
          >
            <Button type="submit">Set</Button>
          </Form.Group>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CustomTitle;
