import axios from 'axios';

const BASE_URL =
  process.env?.NODE_ENV === 'development'
    ? '/api/blackbook/'
    : '/v1/api/blackbook/';

const removeWeirdDoubleQuote = (data) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      typeof value === 'string' ? value.replace(/(â€)/g, '"') : value,
    ])
  );

export const getAllBlackbookItems = async () => {
  const { data } = await axios.get(BASE_URL);

  const cleanedData = removeWeirdDoubleQuote(data);

  return cleanedData;
};

export const getBlackbookItemByRecID = async (recID) => {
  const { data } = await axios.get(BASE_URL, { params: { recID } });

  const cleanedData = removeWeirdDoubleQuote(data);

  return cleanedData;
};

export const getBlackbookItemBySKU = async (SKU) => {
  const { data } = await axios.get(BASE_URL, { params: { SKU } });

  const cleanedData = removeWeirdDoubleQuote(data);

  return cleanedData;
};
