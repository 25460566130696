import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const BlackbookPDFBuilder = (props) => {
  const {
    showPageBuilder,
    handleClose,
    selectedProduct,
    setSelectedProduct,
    selectedPictureIndex,
    setSelectedPictureIndex,
    blackbookPicturesURL,
  } = props;
  const navigate = useNavigate();

  return (
    <Modal show={showPageBuilder} onHide={handleClose} style={{}}>
      <Modal.Header closeButton>
        <Modal.Title>Select Title and Picture</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Title (Pack Size will be included)</Form.Label>
            <Form.Control
              value={selectedProduct['Description']}
              placeholder="Title"
              onChange={({ target: { value } }) =>
                setSelectedProduct({
                  ...selectedProduct,
                  Description: value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Product Picture</Form.Label>
            <Container
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                overflow: 'scroll',
                maxHeight: '65vh',
              }}
            >
              {selectedProduct['pictures']?.split(';').map((img, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid black',
                      marginTop: '1rem',
                      marginBottom: '1rem',
                    }}
                    onClick={() => {
                      setSelectedPictureIndex(i);
                      setSelectedProduct({
                        ...selectedProduct,
                        'Product Picture':
                          selectedProduct['pictures']?.split(';')[i],
                      });
                    }}
                  >
                    <Form.Check
                      type="radio"
                      checked={i === selectedPictureIndex}
                      onChange={() => {
                        setSelectedPictureIndex(i);
                        setSelectedProduct({
                          ...selectedProduct,
                          'Product Picture':
                            selectedProduct['pictures']?.split(';')[i],
                        });
                      }}
                    />
                    <img
                      src={`${blackbookPicturesURL}/${img}`}
                      style={{
                        width: '400px',
                      }}
                      alt={img}
                    />
                  </div>
                );
              })}
            </Container>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (!String(window.location).includes('print')) {
                navigate('/blackbook/print');
              }
              handleClose();
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BlackbookPDFBuilder;
