import { Modal, Form, Button } from 'react-bootstrap';
import { editSDS } from '../api/sdsListApi';
import { useState, useEffect } from 'react';
import { DeleteSDS } from './';

const EditSDS = (props) => {
  const {
    showEdit,
    setShowEdit,
    selectedSDS,
    setSelectedSDS,
    maintenanceSDSList,
    setMaintenanceSDSList,
    sanitationSDSList,
    setSanitationSDSList,
    pestControlSDSList,
    setPestControlSDSList,
  } = props;
  const [changedFields, setChangedFields] = useState({});
  const [editableSDS, setEditableSDS] = useState({});
  const [showDeleteSDS, setShowDeleteSDS] = useState(false);

  useEffect(() => {
    setEditableSDS(selectedSDS);
    setChangedFields({ id: selectedSDS.id });
  }, [selectedSDS]);

  const handleClose = () => {
    setSelectedSDS({});
    setShowEdit(false);
  };

  const updateList = (list, updateFunc, edited) => {
    const updatedList = [...list];
    updatedList[updatedList.map((e) => e.id).indexOf(edited.id)] = edited;
    updateFunc(updatedList);
  };

  const handleSubmit = async () => {
    const clonedFields = { ...changedFields };
    ['English File', 'Spanish File']
      .map((file) => {
        // If the file exists,
        // has not been renamed
        // but the Product Name has been
        if (
          selectedSDS[file] &&
          !changedFields.hasOwnProperty(file) &&
          changedFields.hasOwnProperty('Product Name')
        ) {
          // Construct new filename that will be used to rename the file
          const filePath = selectedSDS[file].slice(
            0,
            selectedSDS[file].lastIndexOf('\\') + 1
          );

          const fileExt = selectedSDS[file].slice(
            selectedSDS[file].lastIndexOf('.')
          );

          const newFilename =
            filePath + changedFields['Product Name'] + fileExt;

          return [file, newFilename];
        }
        return false;
      })
      .filter((e) => e)
      .forEach(([file, fileName]) => (clonedFields[file] = fileName));

    const edited = await editSDS(clonedFields);
    switch (edited['Department']) {
      case 'Maintenance':
        updateList(maintenanceSDSList, setMaintenanceSDSList, edited);
        break;
      case 'Sanitation':
        updateList(sanitationSDSList, setSanitationSDSList, edited);
        break;
      case 'Pest Control':
        updateList(pestControlSDSList, setPestControlSDSList, edited);
        break;
      default:
        console.error('Updated result returned an unknown department');
    }
    handleClose();
  };

  const changeHandler = (key, value) => {
    const clonedEdit = { ...editableSDS };
    clonedEdit[key] = value;
    setEditableSDS(clonedEdit);

    const clonedFields = { ...changedFields };
    selectedSDS[key] === value
      ? delete clonedFields[key]
      : (clonedFields[key] = value);
    setChangedFields(clonedFields);
  };

  const disallowedColumns = ['id'];

  const getFormElement = (key, value, i) => {
    switch (key) {
      case 'Department':
        return (
          <Form.Select
            defaultValue={value}
            onChange={(event) => changeHandler(key, event.target.value)}
          >
            <option value="Maintenance">Maintenance</option>
            <option value="Sanitation">Sanitation</option>
            <option value="Pest Control">Pest Control</option>
          </Form.Select>
        );
      case 'Active':
        return (
          <Form.Select
            defaultValue={value ? 1 : 0}
            onChange={(event) => changeHandler(key, event.target.value)}
          >
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </Form.Select>
        );
      default:
        return (
          <Form.Control
            key={i}
            value={value ?? ''}
            placeholder={key}
            style={{ marginBottom: '.5rem' }}
            onChange={(event) => changeHandler(key, event.target.value)}
          />
        );
    }
  };

  return (
    <>
      <Modal show={showEdit} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {editableSDS['Product Name']}</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <Modal.Body>
            {Object.entries(editableSDS).map(([key, value], i) =>
              disallowedColumns.indexOf(key) ? (
                <Form.Group key={i}>
                  <Form.Label>{key}</Form.Label>
                  {getFormElement(key, value, i)}
                </Form.Group>
              ) : (
                ''
              )
            )}
            <Button variant="danger" onClick={() => setShowDeleteSDS(true)}>
              Delete SDS
            </Button>
          </Modal.Body>
          <Modal.Footer style={{ display: 'flex', justifyContent: 'right' }}>
            <Button onClick={handleClose} variant="secondary">
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '1rem' }}
              type="submit"
              variant="success"
              disabled={Object.keys(changedFields).length === 1}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <DeleteSDS
        showDeleteSDS={showDeleteSDS}
        setShowDeleteSDS={setShowDeleteSDS}
        selectedSDS={selectedSDS}
        handleParentClose={handleClose}
        controlParentVisibility={(bool) => setShowEdit(bool)}
        maintenanceSDSList={maintenanceSDSList}
        setMaintenanceSDSList={setMaintenanceSDSList}
        sanitationSDSList={sanitationSDSList}
        setSanitationSDSList={setSanitationSDSList}
        pestControlSDSList={pestControlSDSList}
        setPestControlSDSList={setPestControlSDSList}
      />
    </>
  );
};

export default EditSDS;
