import { Container, Table, Button, Form } from 'react-bootstrap';
import { BlackbookPDFBuilder, Loading } from './';
import FilterResults from 'react-filter-search';
import { useState } from 'react';

const BlackbookPDFSearch = (props) => {
  const {
    productList,
    selectedProduct,
    setSelectedProduct,
    showPageBuilder,
    setShowPageBuilder,
    showLoading,
    blackbookPicturesURL,
    selectedPictureIndex,
    setSelectedPictureIndex,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [showInactiveSKUs, setShowInactiveSKUs] = useState(false);

  const columns = ['SKU', 'Description', 'Pack Size', 'Status'];

  const handleClose = () => setShowPageBuilder(false);

  return (
    <Container>
      <h1>Blackbook</h1>
      <Form style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Form.Control
          value={searchValue}
          placeholder="Search"
          onChange={({ target: { value } }) => setSearchValue(value)}
        />
        <Form.Check
          value={showInactiveSKUs}
          label="Show inactive SKUs"
          onChange={() => {
            setShowInactiveSKUs(!showInactiveSKUs);
          }}
        />
      </Form>
      <Table bordered striped>
        <thead>
          <tr>
            {columns.map((col, j) => (
              <th key={j}>{col}</th>
            ))}
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          <FilterResults
            value={searchValue}
            data={
              showInactiveSKUs
                ? productList
                : productList.filter(
                    (product) => product['Status'] === 'Active'
                  )
            }
            renderResults={(res) => {
              return res?.map((product, i) => (
                <tr key={i}>
                  {columns.map((col, j) => (
                    <td key={j}>{product[col]}</td>
                  ))}
                  <td>
                    <Button
                      onClick={() => {
                        const possibleProductPic =
                          (product['pictures']?.length &&
                            (product['pictures']
                              .split(';')
                              .filter((e) =>
                                e.toLowerCase().includes('product')
                              )[0] ||
                              product['pictures'].split(';')[0])) ||
                          '';

                        setSelectedPictureIndex(
                          product['pictures']
                            ?.split(';')
                            .findIndex((e) => e === possibleProductPic)
                        );
                        setSelectedProduct({
                          ...product,
                          Title: product['Description'],
                          'Product Picture': possibleProductPic,
                        });
                        setShowPageBuilder(true);
                      }}
                    >
                      Select
                    </Button>
                  </td>
                </tr>
              ));
            }}
          />
        </tbody>
      </Table>
      <BlackbookPDFBuilder
        showPageBuilder={showPageBuilder}
        handleClose={handleClose}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        selectedPictureIndex={selectedPictureIndex}
        setSelectedPictureIndex={setSelectedPictureIndex}
        blackbookPicturesURL={blackbookPicturesURL}
      />
      <Loading showLoading={showLoading} />
    </Container>
  );
};

export default BlackbookPDFSearch;
