import axios from 'axios';

const BASE_URL =
  process.env?.NODE_ENV === 'development' ? '/api/sds/' : '/v1/api/sds/';

export const getMultipleDepartments = async (
  Departments = ['Maintenance', 'Sanitation', 'Pest Control']
) => {
  const data = await Promise.all(
    Departments.map((department) => getSDSByDepartment(department))
  );

  return data;
};

export const getSDSByDepartment = async (Department) => {
  const { data } = await axios.get(BASE_URL, {
    params: { Department },
  });
  return data;
};

export const addSDS = async (SDS) => {
  const { data } = await axios.post(BASE_URL, { ...SDS });
  return data;
};

export const editSDS = async (SDS) => {
  const { data } = await axios.put(BASE_URL, { ...SDS });
  return data;
};

export const deleteSDS = async (id) => {
  const { data } = await axios.delete(BASE_URL, { params: { id } });
  return data;
};

export const openSDS = (filename) => {
  window.open(`${BASE_URL}file?path=${filename}&download=false`, '_blank');
};

export const downloadSDS = (filename) => {
  window.location.href = `${BASE_URL}file?path=${filename}&download=true`;
};
