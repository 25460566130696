import { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { deleteSDS } from '../api/sdsListApi';

const DeleteSDS = (props) => {
  const {
    showDeleteSDS,
    setShowDeleteSDS,
    selectedSDS,
    handleParentClose,
    controlParentVisibility,
    maintenanceSDSList,
    setMaintenanceSDSList,
    sanitationSDSList,
    setSanitationSDSList,
    pestControlSDSList,
    setPestControlSDSList,
  } = props;

  const [yesIAmSure, setYesIAmSure] = useState(false);

  useEffect(() => {
    showDeleteSDS && controlParentVisibility(!showDeleteSDS);
  }, [showDeleteSDS, controlParentVisibility]);

  const handleClose = (parentVis = true) => {
    setShowDeleteSDS(false);
    setYesIAmSure(false);
    controlParentVisibility(parentVis);
  };

  const updateList = (list, updateFunc, deleted) => {
    updateFunc([...list].filter((e) => e.id !== deleted.id));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const deleted = await deleteSDS(selectedSDS.id);
    switch (deleted['Department']) {
      case 'Maintenance':
        updateList(maintenanceSDSList, setMaintenanceSDSList, deleted);
        break;
      case 'Sanitation':
        updateList(sanitationSDSList, setSanitationSDSList, deleted);
        break;
      case 'Pest Control':
        updateList(pestControlSDSList, setPestControlSDSList, deleted);
        break;
      default:
        console.error('Deleted result returned an unknown department');
    }
    handleClose(false);
    handleParentClose();
  };
  return (
    <Modal show={showDeleteSDS} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {selectedSDS['Product Name']}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <p>Are you sure you want to delete the SDS</p>
          <p>"{selectedSDS['Product Name']}"?</p>
          <p>This action cannot be undone.</p>
          <Form.Check
            type="checkbox"
            label="I accept the responsibilities for my actions"
            checked={yesIAmSure}
            onChange={(e) => setYesIAmSure(e.target.checked)}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'right' }}>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button variant="danger" type="submit" disabled={!yesIAmSure}>
            Delete
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DeleteSDS;
