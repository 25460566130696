import { Form } from 'react-bootstrap';

const SearchForm = (props) => {
  const {
    showMaintenace,
    setShowMaintenance,
    showSanitation,
    setShowSanitation,
    showPestControl,
    setShowPestControl,
  } = props;
  
  return (
    <Form>
      <Form.Label>Departments:</Form.Label>
      <Form.Group>
        <Form.Check
          type="switch"
          checked={showMaintenace}
          label="Maintenance"
          onChange={() => setShowMaintenance(!showMaintenace)}
          inline
        />
        <Form.Check
          type="switch"
          checked={showSanitation}
          label="Sanitation"
          onChange={() => setShowSanitation(!showSanitation)}
          inline
        />
        <Form.Check
          type="switch"
          checked={showPestControl}
          label="Pest Control"
          onChange={() => setShowPestControl(!showPestControl)}
          inline
        />
      </Form.Group>
    </Form>
  );
};

export default SearchForm;
