import { useState, useEffect } from 'react';
import {
  NavHeader,
  SearchForm,
  SDSTable,
  Loading,
  EditSDS,
  AddSDS,
} from '../components/';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import FilterResults from 'react-filter-search';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { getMultipleDepartments } from '../api/sdsListApi';

const SDSListApp = () => {
  const [maintenanceSDSList, setMaintenanceSDSList] = useState([]);
  const [sanitationSDSList, setSanitationSDSList] = useState([]);
  const [pestControlSDSList, setPestControlSDSList] = useState([]);
  const [SDSList, setSDSList] = useState([]);
  const [showMaintenace, setShowMaintenance] = useState(true);
  const [showSanitation, setShowSanitation] = useState(true);
  const [showPestControl, setShowPestControl] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [showAddSDS, setShowAddSDS] = useState(false);
  const [selectedSDS, setSelectedSDS] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setShowLoading(true);

        const [maintenance, sanitation, pestControl] =
          await getMultipleDepartments([
            'Maintenance',
            'Sanitation',
            'Pest Control',
          ]);

        setMaintenanceSDSList(maintenance);
        setSanitationSDSList(sanitation);
        setPestControlSDSList(pestControl);
      } catch (error) {
        console.error(error);
      } finally {
        setShowLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const listToSet = [];
    if (showMaintenace) listToSet.push(...maintenanceSDSList);
    if (showSanitation) listToSet.push(...sanitationSDSList);
    if (showPestControl) listToSet.push(...pestControlSDSList);

    setSDSList(listToSet);
  }, [
    maintenanceSDSList,
    showMaintenace,
    sanitationSDSList,
    showSanitation,
    pestControlSDSList,
    showPestControl,
  ]);

  return (
    <>
      <NavHeader searchValue={searchValue} setSearchValue={setSearchValue} />
      <Container>
        <Row>
          <Col>
            <SearchForm
              showMaintenace={showMaintenace}
              setShowMaintenance={setShowMaintenance}
              showSanitation={showSanitation}
              setShowSanitation={setShowSanitation}
              showPestControl={showPestControl}
              setShowPestControl={setShowPestControl}
            />
          </Col>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            <Button onClick={() => setShowAddSDS(true)} variant="success">
              New SDS <BsFillPlusSquareFill />
            </Button>
          </Col>
        </Row>
        <h4>({SDSList.length}) Items</h4>
        <FilterResults
          value={searchValue}
          data={SDSList}
          pick={[
            'Product Name',
            'Company',
            'Key Ingredient',
            'Reg # / CAS # / EPA',
          ]}
          renderResults={(res) => (
            <SDSTable
              SDSList={res}
              setShowEdit={setShowEdit}
              setSelectedSDS={setSelectedSDS}
            />
          )}
        />
      </Container>
      <Loading showLoading={showLoading} />
      <EditSDS
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        selectedSDS={selectedSDS}
        setSelectedSDS={setSelectedSDS}
        maintenanceSDSList={maintenanceSDSList}
        setMaintenanceSDSList={setMaintenanceSDSList}
        sanitationSDSList={sanitationSDSList}
        setSanitationSDSList={setSanitationSDSList}
        pestControlSDSList={pestControlSDSList}
        setPestControlSDSList={setPestControlSDSList}
      />
      <AddSDS
        showAddSDS={showAddSDS}
        setShowAddSDS={setShowAddSDS}
        SDSList={SDSList}
        maintenanceSDSList={maintenanceSDSList}
        setMaintenanceSDSList={setMaintenanceSDSList}
        sanitationSDSList={sanitationSDSList}
        setSanitationSDSList={setSanitationSDSList}
        pestControlSDSList={pestControlSDSList}
        setPestControlSDSList={setPestControlSDSList}
      />
    </>
  );
};

export default SDSListApp;
