import { Table, Button, ButtonGroup } from 'react-bootstrap';
import {
  BsDownload,
  BsFillXSquareFill,
  BsPencilFill,
  BsFileEarmarkPdf,
} from 'react-icons/bs';

import { openSDS, downloadSDS } from '../api/sdsListApi';

const SDSTable = (props) => {
  const { SDSList, setShowEdit, setSelectedSDS } = props;

  const headers = SDSList.length
    ? Object.keys(SDSList[0]).concat(['Edit'])
    : [];

  return (
    <Table striped bordered hover>
      <thead style={{ backgroundColor: '#198754', color: 'white' }}>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {SDSList.map((SDS, i) => (
          <tr key={i}>
            {Object.entries(SDS).map(([key, value], j) =>
              key === 'English File' || key === 'Spanish File' ? (
                <td key={j}>
                  {value && value.length ? (
                    <ButtonGroup>
                      <Button
                        onClick={() => openSDS(value)}
                        variant="secondary"
                        size="sm"
                      >
                        Open
                        <br />
                        <BsFileEarmarkPdf />
                      </Button>
                      <Button onClick={() => downloadSDS(value)} size="sm">
                        Download <br />
                        <BsDownload />
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <Button disabled={true} variant="danger" size="sm">
                      No file
                      <br />
                      <BsFillXSquareFill />
                    </Button>
                  )}
                </td>
              ) : (
                <td key={j}>
                  {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
                </td>
              )
            )}
            <td>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedSDS(SDS);
                  setShowEdit(true);
                }}
              >
                <BsPencilFill />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SDSTable;
