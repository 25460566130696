import { getAllBlackbookItems } from '../api/blackbookPDFApi.js';
import { BlackbookPDFPrintPage, BlackbookPDFSearch } from '../components';
import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

const BlackbookPDFApp = () => {
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showPageBuilder, setShowPageBuilder] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedPictureIndex, setSelectedPictureIndex] = useState(0);
  const blackbookPicturesURL =
    'https://www.test.freshventurefoods.com/dod/php/blackbookPictures';

  useEffect(() => {
    (async () => {
      setShowLoading(true);
      try {
        const res = await getAllBlackbookItems();
        setProductList(res);
      } catch (error) {
        console.error(error);
      } finally {
        setShowLoading(false);
      }
    })();
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <BlackbookPDFSearch
            productList={productList}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            showPageBuilder={showPageBuilder}
            setShowPageBuilder={setShowPageBuilder}
            showLoading={showLoading}
            blackbookPicturesURL={blackbookPicturesURL}
            selectedPictureIndex={selectedPictureIndex}
            setSelectedPictureIndex={setSelectedPictureIndex}
          />
        }
      />
      <Route
        path="print"
        element={
          <BlackbookPDFPrintPage
            product={selectedProduct}
            showPageBuilder={showPageBuilder}
            setShowPageBuilder={setShowPageBuilder}
            blackbookPicturesURL={blackbookPicturesURL}
            selectedPictureIndex={selectedPictureIndex}
            setSelectedPictureIndex={setSelectedPictureIndex}
          />
        }
      />
    </Routes>
  );
};

export default BlackbookPDFApp;
