import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
// import { AttendanceApp, SDSListApp } from './pages'; // TODO: Why doesn't this work?
import SDSListApp from './pages/SDSListApp';
import AttendanceApp from './pages/AttendanceApp';
import BlackbookPDFApp from './pages/BlackbookPDFApp';
import { ErrorPage } from './components';

const App = () => {
  const TempHomePage = (props) => {
    const { pages } = props;

    return (
      <>
        <h1>Site Root</h1>
        <h6>*(Don't worry, this ugly page is temporary)</h6>
        <ul>
          {pages.map(({ link, text }, i) => (
            <li key={i}>
              <Link to={link}>{text}</Link>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path=""
          element={
            <TempHomePage
              pages={[
                { link: '/attendance', text: 'Attendance App' },
                { link: '/sds', text: 'SDS List App' },
                { link: '/blackbook', text: 'Blackbook PDF Generator' },
                { link: '/error', text: 'Error Page' },
              ]}
            />
          }
        />
        <Route path="/attendance/*" element={<AttendanceApp />} />
        <Route path="/sds/*" element={<SDSListApp />} />
        <Route path="/blackbook/*" element={<BlackbookPDFApp />} />
        <Route
          path="*"
          element={
            <ErrorPage
              error={'404 Not Found'}
              message={'Not sure how you got here 😳'}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
