import { useRef, useEffect, useState } from 'react';
import { Container, Spinner, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Scanner = (props) => {
  const {
    showCustomTitle,
    showManuallyAdd,
    showManuallyAddSearch,
    people,
    attendees,
    setAttendees,
  } = props;
  const scannerInputRef = useRef(null);
  const [scannerActive, setScannerActive] = useState(false);
  const [code, setCode] = useState(['']);

  const pollScanner = () => {
    if (
      !showCustomTitle &&
      !showManuallyAdd &&
      !showManuallyAddSearch &&
      scannerInputRef?.current
    ) {
      scannerInputRef.current.focus();
      setScannerActive(true);
    } else {
      setScannerActive(false);
    }
  };

  useEffect(() => {
    pollScanner();
  });

  useEffect(() => {
    if (code[code.length - 1] === '') return;

    const [scannedPerson] = people.filter(
      (person) => code[code.length - 1] === person['Card Hotstamp']
    );

    if (scannedPerson) {
      const inList = attendees.filter(
        (e) => e['Card Hotstamp'] === scannedPerson['Card Hotstamp']
      ).length;

      if (!inList) {
        setAttendees([...attendees, scannedPerson]);
        toast.success(`Added ${scannedPerson['Who']}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error('Error scanning, please try again', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, people]);

  return (
    <>
      <Container>
        {scannerActive ? (
          <h5 style={{ textAlign: 'center' }}>
            Waiting for card scanner input
          </h5>
        ) : (
          <h5 style={{ position: 'absolute', left: '44%' }}>
            Tap here to start scanning
          </h5>
        )}
        <Spinner
          animation="border"
          variant="success"
          size="lg"
          style={{
            position: 'absolute',
            left: '49%',
            visibility: scannerActive ? 'visible' : 'hidden',
          }}
        />
        <Form>
          <Form.Control
            as="textarea"
            style={{ opacity: '0' }}
            ref={scannerInputRef}
            type="number"
            onChange={(event) => {
              event.preventDefault();
              if (event.target.value[event.target.value.length - 1] === '\n') {
                setCode([...code, event.target.value.trim()]);
                event.target.value = '';
              }
            }}
            onBlur={() => pollScanner()}
          />
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Scanner;
