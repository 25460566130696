import { useRef, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const ManuallyAdd = (props) => {
  const { showManuallyAdd, setShowManuallyAdd, attendees, setAttendees } =
    props;
  const handleClose = () => setShowManuallyAdd(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showManuallyAdd && inputRef.current) inputRef.current.focus();
  }, [showManuallyAdd]);

  return (
    <Modal show={showManuallyAdd} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manually Add</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            if (event.target[0].value.length) {
              setAttendees([
                ...attendees,
                {
                  'Card Hotstamp': null,
                  Who: event.target[0].value
                    .split(' ')
                    .map((n) => n[0].toUpperCase() + n.slice(1))
                    .join(' '),
                },
              ]);
            }
            handleClose();
          }}
        >
          <Form.Group>
            <Form.Control ref={inputRef} />
          </Form.Group>
          <Form.Group
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginTop: '1rem',
            }}
          >
            <Button type="submit">Add Attendee</Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ManuallyAdd;
