import { Button, Row, Col, ListGroup } from 'react-bootstrap';
import { BsPersonDashFill, BsPersonPlusFill } from 'react-icons/bs';

const PeopleCard = (props) => {
  const { person, attendees, setAttendees } = props;
  const id = person['Card Hotstamp'];
  const name = person['Who'];

  return (
    <ListGroup.Item>
      <Row>
        <Col style={{ display: 'flex', alignItems: 'end' }}>
          <h5>{name}</h5>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'right' }}>
          {attendees.filter((e) => e['Card Hotstamp'] === id).length ? (
            <Button
              variant="danger"
              onClick={() => {
                setAttendees(
                  attendees.filter((e) => e['Card Hotstamp'] !== id)
                );
              }}
            >
              <BsPersonDashFill />
            </Button>
          ) : (
            <Button onClick={() => setAttendees([...attendees, person])}>
              <BsPersonPlusFill />
            </Button>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
};
export default PeopleCard;
