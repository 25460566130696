import axios from 'axios';

const BASE_URL =
  process.env?.NODE_ENV === 'development'
    ? '/api/meetings'
    : '/v1/api/meetings';

export const addMeeting = async ({ meetingTitle, date, attendees }) => {
  const { data } = await axios.post(`${BASE_URL}/`, {
    meetingTitle,
    date,
    attendees,
  });

  return Promise.resolve(data);
};

export const getAllMeetings = async () => {
  const { data } = await axios.get(BASE_URL);
  return data;
};

export const getMeetingTitles = async () => {
  const { data } = await axios.get(`${BASE_URL}/titles`);
  return data;
};

export const getMeetingsInRange = async ({
  fromDate,
  toDate,
  meetingTitles,
}) => {
  const { data } = await axios.get(`${BASE_URL}/range`, {
    params: {
      fromDate,
      toDate,
      meetingTitles: JSON.stringify(meetingTitles),
    },
  });

  return data;
};

export const getReport = async (query) => {
  window.location.href = `${BASE_URL}/report?${Object.entries(query)
    .map(([key, value]) =>
      typeof value === 'string'
        ? `${key}=${value}`
        : `${key}=${JSON.stringify(value)}`
    )
    .join('&')}`;
};

export const editMeeting = async (meeting) => {
  const { data } = await axios.put(`${BASE_URL}/`, { ...meeting });
  return data;
};

export const deleteMeeting = async (id) => {
  const { data } = await axios.delete(`${BASE_URL}/`, { params: { id } });
  return data;
};
