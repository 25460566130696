import { Modal, Spinner } from 'react-bootstrap';

const Loading = (props) => {
  const { showLoading } = props;

  return (
    <Modal show={showLoading}>
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1>Loading...</h1>
        <Spinner animation="border" variant="success" />
      </Modal.Body>
    </Modal>
  );
};

export default Loading;
