import { Card, Modal, Button, ListGroup } from 'react-bootstrap';
import { addMeeting } from '../api/attendanceApi';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';

const ConfirmSubmit = (props) => {
  const {
    showConfirmSubmit,
    setShowConfirmSubmit,
    meetingTitle,
    setMeetingTitle,
    attendees,
    setAttendees,
    setShowCustomTitle,
    setShowManuallyAdd,
    setShowManuallyAddSearch,
  } = props;
  const navigate = useNavigate();
  const handleClose = () => {
    setMeetingTitle('');
    setAttendees([]);
    setShowCustomTitle(false);
    setShowManuallyAdd(false);
    setShowManuallyAddSearch(false);
    setShowConfirmSubmit(false);
  };

  const submitMeeting = async () => {
    try {
      await toast.promise(
        addMeeting({
          meetingTitle,
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
          attendees: JSON.stringify(attendees),
        }),
        {
          pending: 'Submitting meeting',
          success: `Successfully submitted ${meetingTitle}`,
          error: 'Error submitting meeting, please try again',
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
      navigate('/attendance/meetings');
    }
  };

  return (
    <>
      <Modal show={showConfirmSubmit} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Meeting Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>Meeting Title</Modal.Title>
          <ListGroup>
            <ListGroup.Item>{meetingTitle}</ListGroup.Item>
          </ListGroup>
          <Modal.Title>Attendees</Modal.Title>
          <ListGroup>
            {attendees.map((attendee, i) => (
              <ListGroup.Item key={i}>{attendee.Who}</ListGroup.Item>
            ))}
          </ListGroup>
          <Card.Text
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginTop: '1rem',
            }}
          >
            <Button onClick={() => handleClose()} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                submitMeeting();
              }}
              style={{ marginLeft: '1rem' }}
              variant="success"
            >
              Submit
            </Button>
          </Card.Text>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ConfirmSubmit;
