import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Reports, NavigationBar, ErrorPage } from '../components/';
import fvf_cards from '../data/fvf_cards.json';

const AttendanceApp = () => {
  const [meetingTitle, setMeetingTitle] = useState('');
  const [people] = useState(fvf_cards);
  const [attendees, setAttendees] = useState([]);
  const [showCustomTitle, setShowCustomTitle] = useState(false);
  const [showManuallyAdd, setShowManuallyAdd] = useState(false);
  const [showManuallyAddSearch, setShowManuallyAddSearch] = useState(false);
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const presets = ['FVF Board', 'KPI', 'Managers'];

  return (
    <>
      <NavigationBar
        meetingTitle={meetingTitle}
        setMeetingTitle={setMeetingTitle}
        attendees={attendees}
        setAttendees={setAttendees}
        setShowCustomTitle={setShowCustomTitle}
        setShowManuallyAdd={setShowManuallyAdd}
        setShowManuallyAddSearch={setShowManuallyAddSearch}
        showConfirmSubmit={showConfirmSubmit}
        setShowConfirmSubmit={setShowConfirmSubmit}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              meetingTitle={meetingTitle}
              setMeetingTitle={setMeetingTitle}
              people={people}
              attendees={attendees}
              setAttendees={setAttendees}
              showCustomTitle={showCustomTitle}
              setShowCustomTitle={setShowCustomTitle}
              showManuallyAdd={showManuallyAdd}
              setShowManuallyAdd={setShowManuallyAdd}
              showManuallyAddSearch={showManuallyAddSearch}
              setShowManuallyAddSearch={setShowManuallyAddSearch}
              presets={presets}
            />
          }
        />
        <Route path="/meetings" element={<Reports people={people} />} />
        <Route
          path="*"
          element={
            <ErrorPage
              error={'404 Not Found'}
              message={'Not sure how you got here 😳'}
            />
          }
        />
      </Routes>
    </>
  );
};

export default AttendanceApp;
