import { useState, useRef, useEffect } from 'react';
import { Modal, Form, Card, Button, ListGroup } from 'react-bootstrap';
import FilterResults from 'react-filter-search';
import { PeopleCard } from './';

const ManuallyAddSearch = (props) => {
  const {
    showManuallyAddSearch,
    setShowManuallyAddSearch,
    people,
    attendees,
    setAttendees,
  } = props;
  const handleClose = () => setShowManuallyAddSearch(false);
  const [value, setValue] = useState('');
  const searchRef = useRef(null);

  useEffect(() => {
    if (showManuallyAddSearch && searchRef.current) searchRef.current.focus();
  }, [showManuallyAddSearch]);

  return (
    <Modal show={showManuallyAddSearch} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manually Add Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          ref={searchRef}
          style={{ marginBottom: '1rem' }}
        />
        <ListGroup>
          <FilterResults
            value={value}
            pick={['Who']}
            data={people}
            renderResults={(res) =>
              res.map((person, idx) => (
                <PeopleCard
                  key={idx}
                  person={person}
                  attendees={attendees}
                  setAttendees={setAttendees}
                />
              ))
            }
          />
        </ListGroup>
        <Card style={{ margin: '1rem', textAlign: 'center' }}>
          <Card.Body>
            <Card.Title>Don't see this person?</Card.Title>
            <Card.Text>
              <Button
                onClick={() => {
                  setAttendees([
                    ...attendees,
                    {
                      'Card Hotstamp': null,
                      Who: value
                        .split(' ')
                        .map((n) => n[0].toUpperCase() + n.slice(1))
                        .join(' '),
                    },
                  ]);
                  handleClose();
                }}
              >
                Manually Add {value}
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default ManuallyAddSearch;
