import { Table } from 'react-bootstrap';

const BlackbookPDFTable = (props) => {
  const { product } = props;

  return (
    <Table bordered>
      <thead>
        <tr>
          <th colSpan="5">{product['SKU'] ?? ''}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th className="header">Commodity</th>
          <th className="header">Packaging Size</th>
          <th className="header">Weight/Case</th>
          <th className="header">Storage Temp</th>
          <th className="header">Shelf Life</th>
        </tr>
        <tr>
          <td>{product['Commodity'] ?? ''}</td>
          <td>{product['Pack Size'] ?? ''}</td>
          <td>{product['Case Weight'] ?? ''}</td>
          <td>{product['Storage Temperature'] ?? ''}</td>
          <td>{product['Shelf Life'] ?? ''}</td>
        </tr>
        <tr>
          <th colspan="3" className="header">
            Carton Dimensions
          </th>
          <th colspan="2" className="header">
            Cases/Pallet
          </th>
        </tr>
        <tr>
          <td colspan="3">{product['Carton Dimensions'] ?? ''}</td>
          <td colspan="2">{product['Cases Per Pallet'] ?? ''}</td>
        </tr>
        <tr>
          <th colSpan="5" className="header">
            Description
          </th>
        </tr>
        <tr>
          <td colSpan="5">{product['Description'] ?? ''}</td>
        </tr>
        <tr>
          <th colSpan="5" className="header">
            Cut Sizes
          </th>
        </tr>
        <tr>
          <td colSpan="5">{product['Cut Sizes'] ?? ''}</td>
        </tr>
        <tr>
          <th colSpan="5" className="header">
            Code Format
          </th>
        </tr>
        <tr>
          <td colSpan="5">{product['Code Format'] ?? ''}</td>
        </tr>
        <tr>
          <th colSpan="5" className="header">
            Label Format
          </th>
        </tr>
        <tr>
          <td colSpan="5">{product['Label Format'] ?? ''}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default BlackbookPDFTable;
